import React from "react";
import { graphql } from "gatsby";
import GatsbyLink from "../components/GatsbyLink";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { YoastSEO } from "../components/YoastSEO";
import RelatedPosts from "../components/Related/RelatedPosts";
import { RenderShortcode } from "../components/RenderShortcode";
import { ImagePass } from "../components/Image/ImagePass";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";
import { dateI18n } from "@wordpress/date";
import Layout from "../components/Layout";
import { ProjectPostHeader } from "../components/ProjectPostHeader";
import { GlobalCta } from "../components/Acf/GlobalCta";
import "./post.scss";

export const BlogPostTemplate = ({
  content,
  categories,
  date,
  author,
  showAuthor,
  featured_media,
}) => {
  return (
    <>
      {featured_media && (
        <div className="feature-image">
          <div className="feature-image__inner">
            <ImagePass src={featured_media} />
          </div>
        </div>
      )}
      <section className="single-post">
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="wrap">
                <RenderShortcode content={content} />
              </div>
              {showAuthor && (
                <div className="meta">
                  <h4>Posted by</h4>
                  <div className="author">
                    {author &&
                      author.avatar_urls &&
                      author.avatar_urls.wordpress_48 && (
                        <div className="avatar">
                          <ImagePass src={author.avatar_urls.wordpress_48} />
                        </div>
                      )}
                    <div>
                      {author && (
                        <GatsbyLink to={author.path} className="author">
                          {author.name}
                        </GatsbyLink>
                      )}
                      {date && <span className="date">{date}</span>}
                    </div>
                  </div>
                </div>
              )}
              {categories && categories.length ? (
                <div className="taxonomy">
                  <h4>Categories</h4>
                  <ul className="taglist">
                    {categories.map((category) => (
                      <li key={`${category.slug}cat`}>
                        {category.slug && (
                          <GatsbyLink to={category.path}>
                            {category.name}
                          </GatsbyLink>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const BlogPost = (props) => {
  const { data, location } = props;
  const { wordpressPost: post, siteSettings, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  if (!post) return null;
  const {
    title,
    content,
    featured_media,
    categories,
    date,
    author,
    yoast,
    yoast_head,
  } = post;
  const { blogSlug, date_format } = wordpressWpSettings;
  const dateFormatted = date && date_format && dateI18n(date_format, date);
  return (
    <Layout
      location={location}
      wordpressUrl={wordpressUrl}
      theme={"dark"}
      className="post-template"
    >
      <YoastSEO title={yoast?.metaTitle || title} yoastHead={yoast_head} />
      <ProjectPostHeader
        location={location}
        title={title}
        date={dateFormatted}
      />
      <BlogPostTemplate
        content={content}
        categories={categories}
        title={title}
        date={dateFormatted}
        author={author}
        blogSlug={blogSlug}
        showAuthor={siteSettings.options.showAuthor}
        featured_media={featured_media}
      />
      <RelatedPosts uid="related" postCat={categories[0]?.wordpress_id} />
      <GlobalCta
        custom
        content={`<p><span class="h2">Contact us so we can help you today.</span></p>`}
        link={{ url: `/contact-us/`, title: `Contact Us` }}
      />
    </Layout>
  );
};

export default Previewable(BlogPost, "post");

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      path
      content
      excerpt
      wordpress_id
      date
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      categories {
        name
        slug
        path
        wordpress_id
      }
      author {
        name
        slug
        path
        avatar_urls {
          wordpress_48
        }
      }
      yoast_head
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`;
