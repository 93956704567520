// Header to be used on both project and post individual pages

import React from "react";
import { Breadcrumbs } from "../Breadcrumbs";
import { decodeEntities } from "../../utils/helpers";
import "./ProjectPostHeader.scss";

export const ProjectPostHeader = ({
  title,
  breadcrumbs,
  date,
  location = "",
}) => {
  return (
    <section className="post-header">
      <div className="wrap">
        <div className="inner">
          <div className="preText">
            {date && <span>{date}</span>}
            {breadcrumbs && <Breadcrumbs location={location ? location : ""} />}
          </div>
          <h1>{decodeEntities(title)}</h1>
        </div>
      </div>
    </section>
  );
};
